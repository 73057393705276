import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

const menu = () => (
  <StaticQuery
    query={graphql`
      query allMenus {
        allBtswebMenuJson {
          edges {
            node {
              ID
              Content_ID
              Title
              Link
              Parent
              SortOrder
            }
          }
        }
      }
    `}
    render={data => {
      const realData = data['allBtswebMenuJson'].edges
      return (
        <table style={{ marginLeft: '10px', padding: '5px', width: '80%' }}>
          <tbody>
            <tr>
              <th scope="col">
                <b>ID</b>
              </th>
              <th>Content_ID</th>
              <th>Title</th>
              <th>Link</th>
              <th>Parent</th>
              <th>SortOrder</th>
            </tr>
            {realData.map((menu, i) => {
              const menuData = menu.node
              return (
                <tr key={i}>
                  <th>{menuData.ID}</th>
                  <th>{menuData.Content_ID}</th>
                  <th>{menuData.Title}</th>
                  <th>{menuData.Link}</th>
                  <th>{menuData.Parent}</th>
                  <th>{menuData.SortOrder}</th>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    }}
  />
)

export default menu;
